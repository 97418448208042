import React from 'react';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import Confirmation from '@components/Confirmation/Confirmation';

const ConfirmationPage = ({ location }) => {
  const title = 'Lezione prenotata';
  return (
    <Layout>
      <Seo title={title} url={location.pathname} />
      <Confirmation />
    </Layout>
  );
};

export default ConfirmationPage;
