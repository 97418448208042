import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    paddingTop: '100px',
    textAlign: 'center',
  },
  textContainer: {
    marginBottom: '40px',
    margin: '0 auto',
  },
  subtitle: {
    maxWidth: '450px',
    margin: '0 auto',
  },
  content: {
    paddingBottom: '120px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      paddingBottom: '60px',
    },
  },
  title: {
    fontSize: '40px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '28px',
    },
  },
  wave: {
    marginBottom: '-1px',
  },
};

export default Styles;
