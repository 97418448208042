import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './Confirmation.styles';
import Button from '@components/Button/Button';
import Onda from '@static/img/onda-404.svg';
import { useEffect } from 'react';
import { useState } from 'react';

const useStyles = createUseStyles(Styles);

const Confirmation = ({ to, label, ...props }) => {
  const [client, handleClient] = useState('');
  const [eventName, handleEventName] = useState('');
  const [instructor, handleInstructor] = useState('');
  const classes = useStyles(props);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    handleClient(urlParams.get('invitee_full_name'));
    handleEventName(urlParams.get('event_type_name'));
    handleInstructor(urlParams.get('assigned_to'));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.textContainer}>
          <h1 className={classes.title}>Complimenti {client}!</h1>
          <p className={classes.subtitle}>
            La tua lezione <strong>{eventName}</strong> con{' '}
            <strong>{instructor}</strong> è stata correttamente prenotata! A
            presto!
          </p>
        </div>

        <div className={classes.buttonContainer}>
          <Button to="/lezioni" label="VAI ALLE LEZIONI" />
        </div>
      </div>
      <img className={classes.wave} src={Onda} alt="Liveflow" />
    </div>
  );
};

Confirmation.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default Confirmation;
